import React from "react";
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
// import Video from 'components/Video/Video';
import "./HomeSection.scss";
import bg from 'assets/images/section_0.jpg';
// import bgRight from 'assets/images/bg-video-right.svg';
import Typed from 'react-typed';

import { Trans, withTranslation } from 'react-i18next';
import { TrackingContext } from 'utils'
class HomeSection extends React.PureComponent {
    render() {
        const { onEnter, sourceEN, sourceVN, sourceWebmEN, sourceWebmVN} = this.props;
        return (
            <>
                <Waypoint scrollableAncestor={window} onEnter={onEnter} topOffset={'350px'}>
                <div id="home" className="container">

                    <div className="video-intro">
                        <img data-src={bg} alt="plan" className="bg-left lazyload" />

                        {/* <Video
                            autoPlay={true}
                            mp4={localStorage.getItem('defaultLanguage') && localStorage.getItem('defaultLanguage') === 'vn' ? sourceVN : sourceEN}
                            webm={localStorage.getItem('defaultLanguage') && localStorage.getItem('defaultLanguage') === 'vn' ? sourceWebmVN : sourceWebmEN}
                            loop
                        /> */}
                    </div>

                    <div className="hero-section">
                            <h2 id="home-page-title" className="slogan">
                                <Trans i18nKey="headerTitle" />
                                <Typed
                                    strings={[
                                        'day.','week.'
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={50}
                                    attr="placeholder"
                                    loopCount={0}
                                    loop
                                    backDelay= {2000}
                                    showCursor
                                    cursorChar="|"
                                    smartBackspace
                                    >
                                    <input type="text" className="animation"/>
                                </Typed>
                            </h2>
                            <p className="desc">
                                <Trans i18nKey="headerDes" />
                            </p>
                            <div className="start-planning-container">
                                <a
                                    className="btn btn-primary"
                                href={localStorage.getItem('defaultLanguage') && localStorage.getItem('defaultLanguage') === 'vn' ? 
                                    "https://workplace.macaw-app.com/signup/freeTrial?lang=vi" : 
                                    `https://workplace.macaw-app.com/signup/freeTrial/?section=home${this.context.search && this.context.search !== "" && this.context.search.replace("?","&")}`
                                }
                                title="Start for free"
                                >
                                    <Trans i18nKey="startForFree" />
                                </a>
                            </div>
                    </div>

                </div>
                </Waypoint>
            </>
        );
    }
}

HomeSection.contextType = TrackingContext

HomeSection.propTypes = {
    onEnter: PropTypes.func,
};

HomeSection.defaultProps = {
    onEnter: () => {},
};

export default withTranslation()(HomeSection);

