import React, {Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { routers} from 'routes/routes';

const  Layout = (props) => {
    const dataProps = props;

    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                {routers.map((router,index) => (
                    <Route name={router.name} {...props} key={`router-${index}`} path={router.path} exact={router.isExact} render={(props) => {
                        const C = router.component;
                        return <C {...props} {...dataProps} />
                    }} />
                ))}
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    )

}

export default Layout;