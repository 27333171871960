import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = (props) => {
    const {
        href,
        className,
        children,
        title,
        onClick,
    } = props;

    return (
        href ? (
            <a href={href} className={className && className.join(' ')}title={title} onClick={onClick}>
                {children}
            </a>
        ) : (
            <button type="button" className={className && className.join(' ')} onClick={onClick}>
                {children}
            </button>
        )
    );
};

Button.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    href: null,
    title: '',
    className: [],
    children: null,
    onClick: () => {},
};

export default Button;
