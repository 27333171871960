import React from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

const Section = (props) => {
    const {
        id,
        title,
        subtitle,
        children,
    } = props;

    return (
        <div id={id}>
            <div className="section">
                <div className="section-head">
                    <h2 className="section-title">{title}</h2>
                    <p className="section-subtitle">{subtitle}</p>
                </div>
                <div className="section-body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Section);
