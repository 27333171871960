import React, {useEffect, useState} from 'react';
import Layout from 'Hoc/Layout/Index';
import i18n from 'translate/index';
import { TrackingContext } from 'utils'
// function useLocalStorage(initValue) {
//     const initialState = window.localStorage.getItem("defaultLanguage") || initValue;

//     const changeLanguage = (langChange) => { setLanguage(langChange);  window.localStorage.setItem("defaultLanguage", langChange)};
//     return { language, changeLanguage };
// }

function App(props) {
    const lang = window.location.host !== 'macaw.vn' ? 'en' : 'vn'
    const [language, setLanguage] = useState(lang)

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    // const handleChangeLanguage = (language) => {
    //     if (localStorage.getItem('defaultLanguage') && language !== localStorage.getItem('defaultLanguage')) {
    //         document.location.reload(true)
    //     }

    //     i18n.changeLanguage(language);
    //     changeLanguage(language)
    // }
    const valueContext = {
        search : window.location.search
    }

    return (
        <TrackingContext.Provider value={valueContext}>
            <Layout 
                language={language}
                // handleChangeLanguage={handleChangeLanguage}
            />
        </TrackingContext.Provider>
    );
}

export default React.memo(App);
