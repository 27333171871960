import React, {useContext} from 'react';
import { Trans, withTranslation } from 'react-i18next';
import optionFree from 'assets/images/option-free.svg';
import optionTeam from 'assets/images/option-team.svg';
import check from 'assets/images/check-done.svg';
import { TrackingContext } from 'utils'
import './NewPricingSection.scss';
const NewPricingSection = (props) => {
    const {
        currentOption,
        language
    } = props;

    const trackingContext = useContext(TrackingContext)

    return (
        <div id="new-pricing-section" className="container pricing-section">
            <table className="pricing-option-table">
                <thead>
                    <tr className="">
                        <th className="w-100 option-detail-description"></th>
                        <th className="title-option"><Trans i18nKey="free" /></th>
                        <th className="title-option"><Trans i18nKey="team" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="option-detail-description first-column"></td>
                        <td className="free-option first-column">
                            <img data-src={optionFree} alt="Free" className="w-100 lazyload"/>
                        </td>
                        <td className="team-option first-column">
                            <img data-src={optionTeam} alt="Team" className="w-100 lazyload"/>
                        </td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"></td>
                        <td className="option-detail free-option pricing"><Trans i18nKey="free_forever" /></td>
                        <td className="option-detail team-option pricing">
                            <span>{currentOption ? '6' : '5'}</span>
                            $/ mo
                            </td>
                    </tr> 
                    <tr>
                        <td className="option-detail-description"></td>
                        <td className="option-detail free-option">
                            <a href={language && language === 'vn' ? 
                                "https://workplace.macaw-app.com/signup/freeTrial?lang=vi" : 
                                `https://workplace.macaw-app.com/signup/freeTrial/?section=pricing-free${trackingContext.search && trackingContext.search !== "" && trackingContext.search.replace("?","&")}`}><Trans i18nKey="signup" /></a>
                        </td>
                        <td className="option-detail team-option">
                            <a href={language && language === 'vn' ? 
                                "https://workplace.macaw-app.com/signup/freeTrial?lang=vi" : 
                                `https://workplace.macaw-app.com/signup/freeTrial/?section=pricing-trial${trackingContext.search && trackingContext.search !== "" && trackingContext.search.replace("?","&")}`}><Trans i18nKey="pricing-try-for-free" /></a>
                        </td>
                    </tr> 
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="member" /></td>
                        <td className="option-detail free-option">5</td>
                        <td className="option-detail team-option">25</td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="file_storage" /></td>
                        <td className="option-detail free-option">20 MB</td>
                        <td className="option-detail team-option"><Trans i18nKey="unlimited" /></td>
                    </tr>
                    {/* <tr>
                        <td className="option-detail-description"><Trans i18nKey="file_upload" /></td>
                        <td className="option-detail free-option"><Trans i18nKey="PC" /></td>
                        <td className="option-detail team-option"><Trans i18nKey="from_more" /></td>
                    </tr> */}
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="label" /></td>
                        <td className="option-detail free-option">-</td>
                        <td className="option-detail team-option">
                            <img data-src={check} alt="Team" className="lazyload" />
                        </td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="premium_themes" /></td>
                        <td className="option-detail free-option">-</td>
                        <td className="option-detail team-option">
                            <img data-src={check} alt="Team" className="lazyload" />
                        </td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="member_permissions" /></td>
                        <td className="option-detail free-option">-</td>
                        <td className="option-detail team-option">
                            <img data-src={check} alt="Team" className="lazyload" />
                        </td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="team_product" /></td>
                        <td className="option-detail free-option"><Trans i18nKey="only_u" /></td>
                        <td className="option-detail team-option"><Trans i18nKey="all_ur_team" /></td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="daily_weekly_support" /></td>
                        <td className="option-detail free-option">-</td>
                        <td className="option-detail team-option">
                            <img data-src={check} alt="Team" className="lazyload" />
                        </td>
                    </tr>
                    <tr>
                        <td className="option-detail-description"><Trans i18nKey="priority_support" /></td>
                        <td className="option-detail free-option">-</td>
                        <td className="option-detail team-option">
                            <img data-src={check} alt="Team" className="lazyload" />
                        </td>
                    </tr>
                    <tr>
                        <td className="option-detail-description last-column"></td>
                        <td className="option-detail free-option last-column">
                            <a href={language && language === 'vn' ? 
                                "https://workplace.macaw-app.com/signup/freeTrial?lang=vi" : 
                                `https://workplace.macaw-app.com/signup/freeTrial/?section=pricing-free${trackingContext.search && trackingContext.search !== "" && trackingContext.search.replace("?","&")}`}><Trans i18nKey="signup" /></a>
                        </td>
                        <td className="option-detail team-option last-column">
                            <a href={language && language === 'vn' ? 
                                "https://workplace.macaw-app.com/signup/freeTrial?lang=vi" : 
                                `https://workplace.macaw-app.com/signup/freeTrial/?section=pricing-trial${trackingContext.search && trackingContext.search !== "" && trackingContext.search.replace("?","&")}`}><Trans i18nKey="pricing-try-for-free" /></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default withTranslation()(NewPricingSection);
