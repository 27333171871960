import React from 'react';
import { Waypoint } from 'react-waypoint';
import Slider from 'react-slick';
import { map } from 'lodash';

import themeBlue from "assets/images/Section4_1.jpg";
import themeOrange from "assets/images/Section4_2.jpg";
import themeGreen from "assets/images/Section4_3.jpg";
import './SectionSlider.scss';

const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 2000,
    // speed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true
};

const SectionSlider = (props) => {
    const {
        id,
        title,
        subtitle,
        onEnter,
        language,
        reverse
    } = props;

    const arrSlider = [
        { src: themeBlue },
        { src: themeOrange },
        { src: themeGreen },
    ]
    return (
        <Waypoint scrollableAncestor={window} onEnter={() => onEnter(id)} bottomOffset={'50%'} topOffset={'20%'} >
            <div id={id}>

                <h2 className="slogan">
                    {title}
                </h2>
                <div className="section section-features">
                    <Slider {...settings}>
                        {
                            map(arrSlider, (slider, index) => {
                                return (
                                    <div className="slider-img" key={index}>
                                        <img data-src={slider.src} alt="work flow" className="lazyload" />
                                    </div>
                                );
                            })
                        }
                    </Slider>
                    
                    {/* <div className="section-content">   
                        <h2 className="section-title">{title}</h2>
                        <ul className="section-subtitle">
                            {map(subtitle, (item, index) => (
                                <li className="" key={index}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div> */}
                    
                </div>
            </div>
        </Waypoint>
    );
};

export default React.memo(SectionSlider);
