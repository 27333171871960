import React from 'react';
import { throttle } from 'lodash';
import replace from 'lodash/replace';
import Header from 'components/Header/Header';
import HomeSection from 'components/HomeSection/HomeSection';
import FeaturesSection from 'components/FeaturesSection/FeaturesSection'
// import PricingSection from 'components/PricingSection/PricingSection';
// import ContactUs from 'components/ContactUs/ContactUs';
import Footer from 'components/Footer/Footer';
import 'styles/main.scss';
import Section from 'components/Section/Section';
import history from '../../history';
import SectionSlider from 'components/SectionSlider/SectionSlider';

// import newPlan from "assets/images/new_plan.svg";
import newWorkspace from "assets/images/Section1.jpg";
import newCollaborate from "assets/images/Section2.jpg";
import newEpic from "assets/images/Section3.jpg";
import newTeam from "assets/images/Section4.jpg";

import sourceEN from 'assets/videos/LDP_Eng.mp4';
import sourceWebmEN from 'assets/videos/LDP_Eng.webm';
import sourceVN from 'assets/videos/LDP_vi.mp4';
import sourceWebmVN from 'assets/videos/LDP_vi.webm';

import { Trans, withTranslation } from 'react-i18next';
import { TrackingContext } from 'utils'
// import i18n from 'translate/index';

class LandingPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: 'home',
            planYourDay: false,
            dailyTasks: false,
            gettingAllDone: false,
            idSection: 'home'
        };
        this.handleEnterThrottle = throttle(this.handleEnter.bind(this), 1000);
    }

    componentDidMount() {
        // const { selectedSection } = this.state;
        // this.changeUrl(selectedSection);
    }

    handleEnter = (id) => {
        this.changeUrl(id);
        this.setState({ idSection: id });
        // this.setState({ selectedSection: id });
        // this.playTabs(id);
    }

    changeUrl = (hash) => {

       if(this.props.location && this.props.location.search && this.props.location.search !== null && this.props.location.search !== "")  {
           history.push(`/${this.props.location.search}/#${hash}`) 
       } else {
           history.push(`/#${hash}`)
       }
    }

    render() {
        return (
            <div className="wrapper">
                <Header 
                    // handleChangeLanguage={this.props.handleChangeLanguage} 
                    history={this.props.history}
                    language={this.props.language}
                    idSection={this.state.idSection}
                />
                <HomeSection 
                    onEnter={() => this.handleEnterThrottle('home')}
                    sourceEN={sourceEN}
                    sourceVN={sourceVN}
                    sourceWebmEN={sourceWebmEN}
                    sourceWebmVn={sourceWebmVN}
                    language={this.props.language}
                    idSection="Home"
                />

                <FeaturesSection
                    id="one-page-workspace"
                    title={<Trans i18nKey="objective-setup-title" />}
                    subtitle={[<Trans i18nKey="objective-setup-des-1" />, <Trans i18nKey="objective-setup-des-2" />, <Trans i18nKey="objective-setup-des-3" />]}
                    onEnter={(id) => this.handleEnterThrottle(id)}
                    srcEN={newWorkspace}
                    srcVN={newWorkspace}
                    alt="one page workspace"
                    language={this.props.language}
                />
                <FeaturesSection
                    id="collaborate-effectively"
                    title={<Trans i18nKey="team-alignment-title" />}
                    subtitle={[<Trans i18nKey="team-alignment-des-1" />, <Trans i18nKey="team-alignment-des-2" />, <Trans i18nKey="team-alignment-des-3" />]}
                    onEnter={(id) => this.handleEnterThrottle(id)}
                    srcEN={newCollaborate}
                    srcVN={newCollaborate}
                    reverse
                    alt="collaborate effectively"
                    language={this.props.language}
                />
                <FeaturesSection
                    id="epics"
                    title={<Trans i18nKey="epic-title" />}
                    subtitle={[<Trans i18nKey="epic-des-1" />, <Trans i18nKey="epic-des-2" />]}
                    onEnter={(id) => this.handleEnterThrottle(id)}
                    srcEN={newEpic}
                    srcVN={newEpic}
                    alt="epics"
                    language={this.props.language}
                />
                <FeaturesSection
                    id="team-performance"
                    title={<Trans i18nKey="tracking-monitoring-title" />}
                    subtitle={[<Trans i18nKey="tracking-monitoring-des-1" />, <Trans i18nKey="tracking-monitoring-des-2" />]}
                    onEnter={(id) => this.handleEnterThrottle(id)}
                    srcEN={newTeam}
                    srcVN={newTeam}
                    reverse
                    alt="team performance"
                    language={this.props.language}
                />
                {/* <FeaturesSection
                    id="theme"
                    title={<Trans i18nKey="organizing-title" />}
                    subtitle={[<Trans i18nKey="organizing-des-1" />, <Trans i18nKey="organizing-des-2" />]}
                    onEnter={(id) => this.handleEnterThrottle(id)}
                    srcEN={newOrganizing}
                    srcVN={newOrganizing}
                    reverse
                    alt="team alignment"
                    language={this.props.language}
                /> */}

                <SectionSlider 
                    id="theme"
                    title={<Trans i18nKey="theme-title" />}
                    subtitle={[<Trans i18nKey="organizing-des-1" />, <Trans i18nKey="organizing-des-2" />]}
                    onEnter={(id) => this.handleEnterThrottle(id)}
                />
                
                {/* <PricingSection
                    featuresFree={featuresFree}
                    featuresPro={featuresPro}
                    onEnter={() => this.handleEnter('pricing')}
                /> */}
                {/* <ContactUs
                    title={<Trans i18nKey="start-planning-title" />}
                    description={<Trans i18nKey="start-planning-desc" />}
                    button={<Trans i18nKey="startForFree" />}
                    id="contact_us-section-homepage"
                /> */}
                <Section
                    id="start-planning-section-homepage"
                    title={<Trans i18nKey="start-planning-title" />}
                    subtitle={<Trans i18nKey="start-planning-desc" />}
                    language={this.props.language}
                >
                    <a className="btn btn-primary"
                        href={`https://workplace.macaw-app.com/signup/freeTrial/?section=get-thing-done&${this.props.location.search && this.props.location.search !== "" && this.props.location.search.replace("?","&")}`}
                        title="Start for free"
                    >
                        <Trans i18nKey="startForFree" />
                    </a>
                </Section>
                <Footer 
                    // handleChangeLanguage={this.props.handleChangeLanguage}
                    // language={this.props.language}
                    history={this.props.history}
                />
            </div>
        );
    }
}

LandingPage.contextType = TrackingContext

export default withTranslation()(LandingPage);