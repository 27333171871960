import React, {useEffect} from 'react';
import { Waypoint } from 'react-waypoint';
import './FeaturesSection.scss';
import { map } from 'lodash';

const FeaturesSection = (props) => {
    const {
        id,
        title,
        reverse,
        onEnter,
        srcEN,
        srcVN,
        alt,
        language,
        subtitle
    } = props;

    return (
        <React.Fragment>
            <Waypoint scrollableAncestor={window} onEnter={() => onEnter(id)} 
                bottomOffset={'60%'} topOffset={'30%'}
            >
                <div id={id} className="container">
                    <div className={`section-features ${reverse ? 'section-reverse' : ''}`}>
                        <div className="section-img">
                            <img className="lazyload" data-src={language && language === 'vn' ?srcVN : srcEN} alt={alt} />
                        </div>
                        <div className="section-content">
                            <h2 className="section-title">{title}</h2>
                            <ul className="section-subtitle">
                                {map(subtitle, (item, index) => (
                                    <li className="" key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </React.Fragment>
    );
};

FeaturesSection.defaultProps = {
    id: '',
    title: '',
    subtitle: '',
    reverse: false,
    onEnter: () => { }
};

export default React.memo(FeaturesSection);
