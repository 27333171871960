import React from 'react';
// import { goToAnchor } from 'react-scrollable-anchor';
import { throttle } from 'lodash';
import logo from 'assets/images/logo.svg';
import Button from 'components/Button/Button';
import './Header.scss';

import { Trans, withTranslation } from 'react-i18next';
import { TrackingContext } from 'utils'
import {match} from 'path-to-regexp';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: window.pageYOffset <= 300,
            showHambuger: false,
            showTranslate: false,
        };
        this.handleScrollThrottle = throttle(this.handleScroll.bind(this), 100);

        this.dropdownRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScrollThrottle);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScrollThrottle);
        document.addEventListener('mousedown', this.handleClickOutside);
    }


    handleClickOutside = (e) => {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
            // this.setState({ showTranslate: false });
            this.setState({ showHambuger: false });
        }
    }

    handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible = currentScrollPos <= 300;
        this.setState({ visible });
    }

    handleToggleHambuger = () => {
        const { showHambuger } = this.state;
        this.setState({ showHambuger: !showHambuger });
    }

    handleClickPricingPage = () => {
        if(this.context.search !== "") {
            this.props.history.push(`/pricing/${this.context.search}`)
        } else {
            this.props.history.push(`/pricing`)
        }
        // goToAnchor('pricing');
        this.handleToggleHambuger();
    }

    handleClickHomePage = () => {
        if(this.context.search !== "") {
            this.props.history.push("/"+this.context.search)
        } else {
            this.props.history.push("/")
        }
        this.handleToggleHambuger();
    }

    handleShowTranslate = () => {
        const { showTranslate } = this.state;
        this.setState({ showTranslate: !showTranslate });
    }

    changeLanguage = (lng) => {
        this.props.handleChangeLanguage(lng)
    }

    render () {
        const { visible, showHambuger, showTranslate } = this.state;
        const { idSection } = this.props

        return (
            <header>
                <nav className={`navbar ${!visible ? 'sticky' : ''}`}>
                    <div className="container">
                        <div className="macaw-logo">
                            <img data-src={logo} alt="logo" className="lazyload" />
                        </div>
                        <Button className={["btn-hambuger", "visible-mobile"]} onClick={this.handleToggleHambuger}/>
                        <div className={`navbar-container ${showHambuger ? 'show' : ''}`}>
                            <ul className="navbar-nav" ref={this.dropdownRef}>
                                {/* <li id="menu-nolt" className="nav-item" onClick={() => this.handleToggleHambuger()}>
                                    <button type="button" className="nav-link" alt="featured">Request a feature</button>
                                </li> */}
                                {/* <li className="nav-item language" onClick={this.handleShowTranslate} ref={this.dropdownRef}>
                                    <button type="button" className="btn-translate" alt="translate language" >
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9895 0.5C5.1935 0.5 0.5 5.204 0.5 11C0.5 16.796 5.1935 21.5 10.9895 21.5C16.796 21.5 21.5 16.796 21.5 11C21.5 5.204 16.796 0.5 10.9895 0.5ZM18.266 6.8H15.1685C14.8325 5.4875 14.3495 4.2275 13.7195 3.062C15.6515 3.7235 17.258 5.0675 18.266 6.8ZM11 2.642C11.8715 3.902 12.554 5.2985 13.0055 6.8H8.9945C9.446 5.2985 10.1285 3.902 11 2.642ZM2.873 13.1C2.705 12.428 2.6 11.7245 2.6 11C2.6 10.2755 2.705 9.572 2.873 8.9H6.422C6.338 9.593 6.275 10.286 6.275 11C6.275 11.714 6.338 12.407 6.422 13.1H2.873ZM3.734 15.2H6.8315C7.1675 16.5125 7.6505 17.7725 8.2805 18.938C6.3485 18.2765 4.742 16.943 3.734 15.2ZM6.8315 6.8H3.734C4.742 5.057 6.3485 3.7235 8.2805 3.062C7.6505 4.2275 7.1675 5.4875 6.8315 6.8ZM11 19.358C10.1285 18.098 9.446 16.7015 8.9945 15.2H13.0055C12.554 16.7015 11.8715 18.098 11 19.358ZM13.457 13.1H8.543C8.4485 12.407 8.375 11.714 8.375 11C8.375 10.286 8.4485 9.5825 8.543 8.9H13.457C13.5515 9.5825 13.625 10.286 13.625 11C13.625 11.714 13.5515 12.407 13.457 13.1ZM13.7195 18.938C14.3495 17.7725 14.8325 16.5125 15.1685 15.2H18.266C17.258 16.9325 15.6515 18.2765 13.7195 18.938ZM15.578 13.1C15.662 12.407 15.725 11.714 15.725 11C15.725 10.286 15.662 9.593 15.578 8.9H19.127C19.295 9.572 19.4 10.2755 19.4 11C19.4 11.7245 19.295 12.428 19.127 13.1H15.578Z" fill="#8C8C8C" />
                                        </svg>

                                        {this.props.language && this.props.language === 'vn' ? <Trans i18nKey="vn" /> : <Trans i18nKey="en" />}
                                    </button>
                                    <div className={`dropdown ${showTranslate ? 'showTrans' : ''}`}>
                                        <Button onClick={() => this.changeLanguage('en')}><Trans i18nKey="en" /></Button>
                                        <Button onClick={() => this.changeLanguage('vn')}><Trans i18nKey="vn" /></Button>
                                    </div>
                                </li> */}
                                <li className={`nav-item ${!match("/pricing/(.*)?", { decode: decodeURIComponent })(this.props.history.location.pathname) ? 'active': ''}`} onClick={() => this.handleClickHomePage()}>
                                    <button type="button" className="nav-link" alt="product" ><Trans i18nKey="product-title" /></button>
                                </li>
                                <li className={`nav-item ${match("/pricing/(.*)?", { decode: decodeURIComponent })(this.props.history.location.pathname) ? 'active' : ''}`} onClick={() => this.handleClickPricingPage()} >
                                    <button type="button" className="nav-link" alt="pricing" ><Trans i18nKey="pricing-title" /></button>
                                </li>
                                <li className="nav-item">
                                    <button type="button" className="nav-link" alt="support"><a href={this.props.language && this.props.language === 'vn' ? "https://workplace.macaw-app.com/login?lang=vi" : "https://workplace.macaw-app.com/login" }><Trans i18nKey="login"/></a></button>
                                </li>
                            </ul>

                            <div className="btn-start-for-free">
                                <Button
                                    className={["btn", "btn-primary"]}
                                    href={this.props.language && this.props.language === 'vn' ? `https://workplace.macaw-app.com/signup/freeTrial?lang=vi&section=${idSection}${this.context.search && this.context.search !== "" && this.context.search.replace("?","&")}` : `https://workplace.macaw-app.com/signup/freeTrial/?section=${idSection}${this.context.search && this.context.search !== "" && this.context.search.replace("?","&")}`}
                                    title="Start for free"
                                >
                                    <Trans i18nKey="startForFree" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

        );
    }
}

Header.contextType = TrackingContext

export default withTranslation()(Header);
