import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import './QuestionsSection.scss';

const QuestionsSection = (props) => {

    return (
        <div id="question-section" className="container">
            <div className="question-section_title">
                <h2>
                    <Trans i18nKey="asked_questions" />
                </h2>
            </div>

            <div className="question-section_body">
                <div className="asked_questions">
                    <div className="question">
                        <h4><Trans i18nKey="use_free" /></h4>
                        <p><Trans i18nKey="use_free_desc" /></p>
                    </div>

                    <div className="question">
                        <h4><Trans i18nKey="upgrade_everyone" /></h4>
                        <p><Trans i18nKey="upgrade_everyone_desc" /></p>
                    </div>
                </div>

                <div className="asked_questions">
                    <div className="question">
                        <h4><Trans i18nKey="pro_expires" /></h4>
                        <p><Trans i18nKey="pro_expires_desc" /></p>
                    </div>

                    <div className="question">
                        <h4><Trans i18nKey="pay_for_pro" /></h4>
                        <p><Trans i18nKey="pay_for_pro_desc" /></p>
                    </div>
                </div>

                {/* <div className="asked_questions">
                    <div className="question">
                        <h4><Trans i18nKey="discount_pro" /></h4>
                        <p><Trans i18nKey="discount_pro_desc" /></p>
                    </div>

                    <div className="question">
                        <h4><Trans i18nKey="how_upgrade_pro" /></h4>
                        <p><Trans i18nKey="how_upgrade_pro_desc" /></p>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default withTranslation()(QuestionsSection);
