import React, { useState, useEffect } from 'react';
import Header from 'components/Header/Header';
import PricingHomeSection from 'components/PricingHomeSection/PricingHomeSection';
import NewPricingSection from 'components/NewPricingSection/NewPricingSection';
import QuestionsSection from 'components/QuestionsSection/QuestionsSection';
import ContactUs from 'components/ContactUs/ContactUs';
import Footer from 'components/Footer/Footer';
import { Trans, withTranslation } from 'react-i18next';
import 'styles/main.scss';

const PricingPage = (props) => {
    const [currentOption, setCurrentOption] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div className="wrapper" id="pricing-page">
            <Header 
                handleChangeLanguage={props.handleChangeLanguage}
                history={props.history}
                language={props.language}
                idSection="pricing"
            />
            <PricingHomeSection 
                currentOption={currentOption}
                setCurrentOption={setCurrentOption}
                language={props.language}
            />
            <NewPricingSection 
                currentOption={currentOption}
                language={props.language}
            />
            <QuestionsSection language={props.language}/>
            <ContactUs 
                title={<Trans i18nKey="contact_us_title" />}
                button={<Trans i18nKey="contact_us_btn" />}
                id="contact_us-section"
                language={props.language}
            />
            <Footer
                history={props.history}
            />
        </div>
    );
};

export default withTranslation()(PricingPage);
