import React, { useState } from "react";
import { linkedin, facebook, appstore } from "../../assets/index";
import "./Footer.scss";
import Button from 'components/Button/Button';
import { Trans, withTranslation } from 'react-i18next';

const Footer = (props) => {
    const {
        history
    } = props;

    const [showTranslate, setShowTranslate] = useState(false);
    

    const handleClickPolicy = () => {
        history.push(`/privacy`);
    }

  return (
    <footer className="footer">
        <div className="container">
            <div className="info">
                <p className="copyright">
                    © Macaw Work Lab LLC
                </p>
                <span>
                    <p className="policy" onClick={() => handleClickPolicy()}>
                        Policy
                    </p>
                    <a className="contact" href="mailto:support@macaw-app.com">
                        support@macaw-app.com
                    </a>
                </span>
            </div>
            <div className="social">
                <a href="https://apps.apple.com/au/app/macaw-get-things-done/id1438822168" target="blank" className="item-appstore" title="App Store">
                    <img data-src={appstore} alt="app-store" className="lazyload"/>
                </a>
                <a href="https://www.facebook.com/macawapp/" className="item-social" target="blank" title="LinkedIn">
                    <img data-src={facebook} alt="icon-facebook" className="lazyload"/>
                </a>
                <a href="https://www.linkedin.com/company/14626042/" className="item-social" target="blank" title="LinkedIn">
                    <img data-src={linkedin} alt="icon-linkedin" className="lazyload"/>
                </a>
            </div>
        </div>
    </footer>
  );
};

export default withTranslation()(Footer);
