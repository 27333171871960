import React from 'react';

const Demo = (props) => {
    return (
        <div className="">
            This is page 404
        </div>
    );
};

export default Demo;
