import React from 'react';
import LandingPage from 'containers/LandingPage/LandingPage';

const Demo = React.lazy(() => import('containers/Demo/Index'));
const Page404 = React.lazy(() => import('containers/404'));
const Pricing = React.lazy(() => import('containers/PricingPage/PricingPage'));
const Policy = React.lazy(() => import('containers/PolicyPage'));

export const routers = [
  {
    path: "/",
    title: "Macaw",
    component: React.memo(LandingPage),
    isProtected: false, // or false
    isExact: true // or false
  }, {
    path: "/demo",
    component: Demo,
    title: "Sign Up",
    isProtected: false, // or false
    isExact: false // or false
  }, {
    path: "/pricing",
    component: Pricing,
    title: "Pricing",
    isProtected: false, // or false
    isExact: false // or false
  }, {
    path: "/privacy",
    component: Policy,
    title: "Policy",
    isProtected: false, // or false
    isExact: false // or false
  }, {
    path: "*",
    component: Page404,
    isProtected: false, // or false
    isExact: false // or false
  }
]