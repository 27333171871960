import React, { useState, useEffect } from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import logo from 'assets/images/logo.svg';
import 'styles/main.scss';
import './index.scss';

const PolicyPage = (props) => {
    const [currentOption, setCurrentOption] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div className="wrapper" id="policy-page">
            <div className="policy-page__header">
                <div className="macaw-logo" onClick={() => {props.history.push('/')}}>
                    <img data-src={logo} alt="logo" className="lazyload"/>
                </div>
            </div>

            <div className="policy-page__body">
                <div className="policy-page__body--title">
                    Macaw Privacy Policy
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__content strong mb-24">
                        When you use our services, you're trusting us with your information. We understand this is a big responsibility and work hard to protect your information and give you control.
                    </p>
                    <p className="policy-page__body--section__content">
                        By accessing or using our services, you agree to this Privacy Policy and our Terms of Service. If you have any questions about this Privacy Policy, please contact us.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                        Information That We Collects
                    </p>

                    <p className="policy-page__body--section__title">
                        Your activity & apps
                    </p>
                    <p className="policy-page__body--section__content">
                        By accessing or using our services, you agree to this Privacy Policy and our Terms of Service. If you have any questions about this Privacy Policy, please contact us.
                    </p>

                    <p className="policy-page__body--section__title">
                        Your browsers & devices
                    </p>
                    <p className="policy-page__body--section__content">
                        We gather data about the browsers and devices you use to access Macaw. This user data assists us in improving Macaw and the quality of our service. We only collect the personal data necessary to provide our services, and we only store it as long as it is essential for these services.
                    </p>

                    <p className="policy-page__body--section__title">
                        Your avatar picture
                    </p>
                    <p className="policy-page__body--section__content">
                        We collect user avatars to differentiate users and enable features such as task sharing. This information is used for functional purposes only.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                        Why Macaw Collects Data
                    </p>
                    <p className="policy-page__body--section__content">
                        We use data to build better services. Your information will not be shared with others and will only be used internally for the purposes described below:
                    </p>

                    <p className="policy-page__body--section__title">
                        Provide our services
                    </p>
                    <p className="policy-page__body--section__content">
                        We use your information to deliver our services or information you requested, and to process and complete any transactions.
                    </p>

                    <p className="policy-page__body--section__title">
                        Maintain & improve our services
                    </p>
                    <p className="policy-page__body--section__content">
                        We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information to make improvements to our services - for example, to analyze usage and trends with anonymous user data, and to improve the quality of our service and user experience.
                    </p>

                    <p className="policy-page__body--section__title">
                        Communicate with you
                    </p>
                    <p className="policy-page__body--section__content">
                        Macaw collects email addresses from those who communicate with us via email, as well as information provided through voluntary activities such as site registration or survey participation. We use the information we collect to interact with you directly.
                    </p>

                    <p className="policy-page__body--section__title">
                        Protect Macaw, our users, and the public
                    </p>
                    <p className="policy-page__body--section__content">
                        We use information to help improve the safety and reliability of our services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm Macaw, our users, or the public.<br></br>
                        We use different technologies to process your information for these purposes. We'll ask for your consent before using your information for a purpose that isn't covered in this Privacy Policy.<br></br>
                        You're also welcome to contact us to ask for our confirmation whenever you feel concerned about how your personal information is being processed, where and for what purpose.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                    Your Privacy Controls
                    </p>
                    <p className="policy-page__body--section__content">
                    While Macaw owns the Macaw application's code, databases, and all rights, you retain all rights to your data. You have choices regarding the information we collect and how it's used. Macaw will never sell your personal data to a third party, and we will never share data with third parties without your permission. When absolutely necessary, Macaw uses third party services and hosting partners who are GDPR-compliant. In such instances, we take essential security methods to guarantee that we comply with GDPR when sending and receiving data from third parties.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                    Cookies
                    </p>
                    <p className="policy-page__body--section__content">
                    Cookies are required on Macaw Website. We use them to collect visitors' preferences and thus to better optimize the user experience. Users can disable cookies in their browser settings. But please note that our services rely on cookies to function properly, and you may not be able to access certain features on our Website after disabling them.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                    Data Retention & Secure
                    </p>
                    <p className="policy-page__body--section__content">
                    Macaw is built with strong security features that continuously protect your information. SSL protocol secures all data and information transmitted with Service. The insights we gain from maintaining our services help us detect and automatically block security threats from ever reaching you. And if we do detect something risky that we think you should know about, we'll notify you and help guide you through steps to stay better protected.<br></br>
                    We work hard to protect you and Macaw from unauthorized access, alteration, disclosure, or destruction of information we hold. You can learn more about our security measures from the Security page.
                    </p>

                    <p className="policy-page__body--section__title">
                    How long we store your information
                    </p>
                    <p className="policy-page__body--section__content">
                    We keep the information until you delete your account, and the information will keep in our backup archives for 90 days after the account is deleted. For subscriptions and transaction records, we retain this information after the account is deleted.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                    Data Deletion
                    </p>
                    <p className="policy-page__body--section__content">
                    You can delete your Macaw account with "Avatar - Settings - Profile " in Macaw. If you terminate your Account, any association between your Account and information we store will no longer be accessible through your Account. We will stop providing products and/or services to you and delete your personal information as required by you.
                    </p>
                </div>

                <div className="policy-page__body--section">
                    <p className="policy-page__body--section__header">
                    Changes to This Policy
                    </p>
                    <p className="policy-page__body--section__content">
                    If our Privacy Policy changes at some time in the future, we will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the latest changes were published. If changes are significant, we'll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).<br></br>
                    Please check back periodically to keep informed of updates or changes to this Privacy Policy. By continuing to access and to use Macaw, you are agreeing to be bound by the revised policy.
                    </p>
                </div>
                
            </div>

            <div className="policy-page__date-revision">
                Date of Last Revision: Feb 02, 2024
            </div>
            <Footer
                // handleChangeLanguage={props.handleChangeLanguage}
                // language={props.language}
                history={history}
            />
        </div>
    );
};

export default PolicyPage;
