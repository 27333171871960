import React from 'react';
import { Trans, withTranslation } from 'react-i18next'; 
import Switch from "react-switch";
import './PricingHomeSection.scss';

const PricingHomeSection = (props) => {
    const {
        currentOption,
        setCurrentOption,
    } = props;

    const handleChange = () => {
        setCurrentOption(!currentOption)
    }
    return (
        <div id="pricing-home" className="container home-section">
            <h2 className="slogan">
                <Trans i18nKey="pricing-page-title" />
            </h2>
            <p className="desc">
                <Trans i18nKey="pricing-page-desc" />
            </p>

            <div className="option-pricing">
                <p className={`${currentOption ? '' : 'active'}`}><Trans i18nKey="pricing-yearly" /></p>
                <Switch 
                    onChange={handleChange}
                    checked={currentOption}
                    offColor="#4FACFE"
                    onColor="#4FACFE"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={31}
                    width={51}
                    handleDiameter={29}
                />
                <p className={`${currentOption ? 'active' : ''}`}><Trans i18nKey="pricing-monthly" /></p>
            </div>
        </div>
    );
};

export default withTranslation()(PricingHomeSection);
