import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import './ContactUs.scss';

const ContactUs = (props) => {
    const {
        title,
        description,
        button,
        id
    } = props;

    return (
        <div id={id} className="">
            <h2 className="slogan">
                {title}
            </h2>
            <p>{description}</p>
            <a className="contact-us" href="mailto:support@macaw-app.com" title="Contact us">
                {button}
            </a>
        </div>
    );
};

export default withTranslation()(ContactUs);
